import VueRouter from "vue-router"
export default new VueRouter({
    mode: 'history',
    routes:[
        {
            path: '/',
            redirect: '/homePage',
            meta: {
                title: '千越医疗官网',
            },
        },
        {
            path: '/logoIndex',
            name: 'logoIndex',
            component: () => import('../view/logoIndex.vue')
        },
        {
            path: '/toApplet',
            name: 'toApplet',
            hash: true,
            component: () => import('../view/HelloWorld.vue')
        },
        {
            path: '/viewDetail',
            name: 'viewDetail',
            component: () => import('../view/viewDetail.vue')
        },
        {
            path: '/homePage',
            name: 'homePage',
            component: () => import('../view/homePage.vue')
        },
        {
            path: '/applyOrg',
            name: 'applyOrg',
            component: () => import('../view/applyOrg.vue')
        },
        {
            path: '/Home/AppDown',
            name: 'AppDown',
            component: () => import('../view/AppDown.vue')
        },
        {
            path: '/Home/AppHisDown',
            name: 'AppHisDown',
            component: () => import('../view/AppHisDown.vue')
        },
        {
            path: '/Home/AppAssociationDYGDown',
            name: 'AppAssociationDYGDown',
            component: () => import('../view/AppDYGDown.vue')
        },
        {
            path: '/Home/AppServiceDown',
            name: 'AppServiceDown',
            component: () => import('../view/AppServiceDown.vue')
        }
        
    ]
})