import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import axios from 'axios';
import 'amfe-flexible';
import ElementUI from 'element-ui'
import './assets/font/font.css'
import 'element-ui/lib/theme-chalk/index.css'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'C3L8oD0NokcKDAyhtGZgTtwF1R4eqo7Sq'  //这个地方是官方提供的ak密钥
})
Vue.prototype.$axios = axios;

router.beforeEach((to, from, next) => {
  console.log(to,'打印路由是什么丫丫');
	if (to.hash == '#/toApplet' ) {
		next({
      path: '/toApplet',
    })
	} else {
		next()
	}
})

Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.directive('title', {//单个修改标题
  inserted: function (el, binding) {
  document.title = el.dataset.title
  }
})
import router from './router'
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
