<template>
  <div id="app" v-title data-title="千越医疗官网">
    <router-view ></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
   //APP下载路径
//  "AppDownAddress": "/apk/qyyl.apk",
//  "AppHisDownAddress": "/apkHis/hisqyyl.apk",
//  "AppServiceDownAddress": "/totalApK/totalApk.apk",
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;  
}
* {
  margin: 0;
  padding: 0;
}
</style>
